import styled from 'styled-components';
import { media } from 'theme';
import { Search } from 'react-feather';

interface InputWrapperProps {
  $isFocused?: boolean;
  borderHover?: string;
  inputPadding?: string;
}

export const Wrapper = styled.div<InputWrapperProps>`
  display: flex;
  flex-direction: row;
  background: ${props => props.theme.colors.neutral2};

  transition: border 0.5s ease;

  border: ${props =>
    props.$isFocused
      ? props.borderHover
      : `solid 0px ${props.theme.colors.neutral2}`};
  color: ${props => props.theme.colors.neutral2};
  border-radius: ${props => props.theme.borderRadius.md};
  height: 35px;
  width: 100%;

  padding: ${props => props.inputPadding || '10px 14px'};
  margin: ${props => props.theme.spacing.xxs} auto;

  align-items: center;

  ${props => media.largeAndDown`
    width: 300px
    margin: ${props.theme.spacing.xxs};
  `}
`;

export const Input = styled.input<{ inputFontSize: string }>`
  border: none;
  background: none;
  font-size: ${props => props.inputFontSize};
  width: 100%;
  height: 19px;
  padding: 0;

  ::placeholder {
    color: ${props => props.theme.colors.neutral5};
  }

  &:focus {
    outline: none;
  }

  ${media.largeAndDown`
    font-size: 16px;
  `}
`;

export const StyledMagnifyIcon = styled(Search)<InputWrapperProps>`
  transition: fill 0.2s ease;
  color: ${props =>
    props.$isFocused ? props.theme.colors.seedlyBlue : 'black'};
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  margin-right: ${props => props.theme.spacing.xs};
`;

export const ClearButton = styled.button`
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-top: ${props => props.theme.spacing.xxs};

  svg {
    fill: ${props => props.theme.colors.neutral5};
    stroke: ${props => props.theme.colors.neutral2};
  }
`;
