import React from 'react';
import clsx from 'clsx';

interface OwnProps {
  indent?: boolean;
  emoji: string;
  emojiAriaLabel: string;
  children: React.ReactNode;
}

const SectionHeader = (props: OwnProps) => {
  const { indent = false } = props;

  return (
    <p className={clsx('text-xl font-bold', indent && 'ml-4 lg:ml-0')}>
      <span role="img" aria-label={props.emojiAriaLabel}>
        {props.emoji}
      </span>{' '}
      {props.children}
    </p>
  );
};

export default SectionHeader;
