import React from 'react';
import { MessageCircle } from 'react-feather';
import { LabelTag, Colors } from '@component-lib';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

import BookmarkButton from 'components/post/BookmarkButton';
import { SAVE_TYPE } from 'constants/app';

interface OwnProps {
  postId: number;
  postSlug: string;
  tagText?: string;
  tagColor?: string;
  HeaderComponent?: React.ReactNode;
  title?: string;
  titleHref?: string;
  dayAgoText?: string;
  numberOfCommentsText?: string;
  bookmarked: boolean;
  bookmarkType: keyof typeof SAVE_TYPE;
}

const TrendingCard = (props: OwnProps) => {
  const {
    postId,
    postSlug,
    tagText = '',
    tagColor = Colors.seedlyBlue,
    HeaderComponent = null,
    title = '',
    titleHref = '',
    dayAgoText = '',
    numberOfCommentsText = '',
    bookmarked,
    bookmarkType,
  } = props;

  return (
    <div className="card w-[200px] h-[171px] pt-4 pb-3 px-3 rounded-lg relative">
      <LabelTag className="absolute -top-3 left-2" bgColor={tagColor}>
        {tagText}
      </LabelTag>
      <div className="flex flex-col justify-between w-full h-full gap-2">
        <div className="flex flex-col gap-2 w-full">
          {HeaderComponent}
          <a
            className="font-bold text-sm h-full underline hover:text-blue-500"
            href={titleHref}
            onClick={() => {
              mixpanel.track(trackedEvents.HomePage, {
                param: `${tagText.toLowerCase()}-${postId}`,
              });
            }}
          >
            {title}
          </a>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex gap-1 items-center">
            <p className="text-neutral-500">{dayAgoText}</p>
            <p className="text-neutral-500">&#x2022;</p>
            <div className="flex items-center gap-1">
              <MessageCircle size={16} className="text-neutral-500" />
              <p className="text-neutral-500">{numberOfCommentsText}</p>
            </div>
          </div>
          <BookmarkButton
            size={20}
            contentId={postId}
            saved={bookmarked}
            slug={postSlug}
            type={SAVE_TYPE[bookmarkType]}
            showIconOnly
          />
        </div>
      </div>
    </div>
  );
};

export default TrendingCard;
