import React from 'react';

import data from './about-seedly';

const AboutSeedly = () => {
  return (
    <div className="flex flex-col gap-10 items-center">
      <p className="text-base text-white font-bold text-center">
        <span role="img" aria-label="rocket">
          🚀
        </span>{' '}
        Kickstart your personal finance journey with Seedly
      </p>
      <div className="w-full lg:w-[1024px] grid gap-6 lg:gap-10 grid-cols-2 lg:grid-cols-4">
        {data.map(description => {
          return (
            <div
              className="flex flex-col gap-1 items-center"
              key={description.title}
            >
              <div className="flex flex-col items-center">
                <p className="text-white">{description.icon}</p>
                <p className="text-white text-base font-bold">
                  {description.title}
                </p>
              </div>
              <p className="text-white text-center">
                {description.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutSeedly;
