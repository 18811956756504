import React from 'react';
import NextImage from 'next/image';

import { homeTabIcons } from 'theme';

const ICON_WIDTH = 40;

export default [
  {
    title: 'Community',
    icon: (
      <NextImage
        src={homeTabIcons.communityWhite}
        alt="rewards"
        width={ICON_WIDTH}
        height={ICON_WIDTH}
        layout="fixed"
      />
    ),
    description:
      'Be part of the hottest discussions on all things personal finance',
  },
  {
    title: 'Blog',
    icon: (
      <NextImage
        src={homeTabIcons.blogWhite}
        alt="rewards"
        width={ICON_WIDTH}
        height={ICON_WIDTH}
        layout="fixed"
      />
    ),
    description:
      'Read the latest personal finance tips - from insurances & credit cards to the latest lifestyle hacks',
  },
  {
    title: 'Product Reviews',
    icon: (
      <NextImage
        src={homeTabIcons.reviewsWhite}
        alt="rewards"
        width={ICON_WIDTH}
        height={ICON_WIDTH}
        layout="fixed"
      />
    ),
    description:
      'Read reviews, compare, and apply for the best financial products to suit your needs',
  },
  {
    title: 'Rewards',
    icon: (
      <NextImage
        src={homeTabIcons.giftWhite}
        alt="rewards"
        width={ICON_WIDTH}
        height={ICON_WIDTH}
        layout="fixed"
      />
    ),
    description:
      'Get rewarded with attractive promotions and deals when you engage on Seedly ',
  },
];
