import React, { useContext, useRef, useEffect } from 'react';
import { XCircle } from 'react-feather';
import colors from 'seedly-component-library/themes/colors';
import { SearchContext } from '../SearchProvider';
import * as S from './styles';

interface OwnProps {
  variant: 'home' | 'header';
}

const SearchBarTheme = {
  header: {
    inputPadding: '10px 14px',
    inputFontSize: '14px',
    boderHover: `solid 1px ${colors.seedlyBlue}`,
  },
  home: {
    inputPadding: '28px 16px',
    inputFontSize: '16px',
    boderHover: `solid 0px ${colors.neutral2}`,
  },
};

const SearchInput = ({ variant = 'header' }: OwnProps) => {
  const {
    searchTerm,
    setSearchTerm,
    isInputFocus,
    setIsInputFocus,
    showMobileResult,
  } = useContext(SearchContext);

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (showMobileResult) {
      searchInputRef.current.focus();
    }
  }, [showMobileResult]);

  const searchBarTheme = SearchBarTheme[variant];

  return (
    <S.Wrapper
      borderHover={searchBarTheme.boderHover}
      inputPadding={searchBarTheme.inputPadding}
      $isFocused={isInputFocus}
    >
      <S.StyledMagnifyIcon $isFocused={isInputFocus} />
      <S.Input
        ref={searchInputRef}
        inputFontSize={searchBarTheme.inputFontSize}
        onFocus={() => setIsInputFocus(true)}
        onBlur={() => setIsInputFocus(false)}
        onChange={e => {
          const term = e.target.value;
          setSearchTerm(term);
        }}
        placeholder="Search for articles, discussions and products"
      />
      {searchTerm && searchTerm.length > 0 && (
        <S.ClearButton
          onClick={() => {
            searchInputRef.current.value = '';
            searchInputRef.current.focus();
            setSearchTerm('');
          }}
        >
          <XCircle size={22} />
        </S.ClearButton>
      )}
    </S.Wrapper>
  );
};

export default SearchInput;
