import React, { useRef } from 'react';
import { createLocalSearch } from 'constants/searchComparison';
import Link from 'next/link';
import { Text } from '@component-lib';
import {
  SearchResultWrapper,
  SearchItemWrapper,
  SearchTitleWrapper,
} from './styles';

interface OwnProps {
  resultList: ISearchResult[];
  onResultClick: () => void;
  cursor: number;
  searchTerm: string;
  width?: string;
}

export interface ISearchResult {
  searchItem: React.ReactNode;
  link: string;
  title?: string;
  withBottomDivider?: boolean;
  item?: any;
}

const SearchResult = (props: OwnProps) => {
  const {
    resultList,
    cursor = -1,
    searchTerm,
    onResultClick = () => {},
    width,
  } = props;

  const localSearch = useRef(createLocalSearch());

  return (
    <SearchResultWrapper width={width}>
      {!searchTerm && (
        <SearchTitleWrapper>
          <Text variant="titlemd">Recent Search</Text>{' '}
        </SearchTitleWrapper>
      )}
      {resultList.map((searchResult, index) => {
        const isHighlight = index === cursor;
        return (
          <>
            {searchResult.title && (
              <SearchTitleWrapper>
                <Text intent="secondary">{searchResult.title}</Text>
              </SearchTitleWrapper>
            )}
            <Link href={searchResult.link}>
              <a
                onClick={() => {
                  if (searchResult.item) {
                    localSearch.current?.save(searchResult.item);
                  }
                  onResultClick();
                  return true;
                }}
              >
                <SearchItemWrapper
                  withBottomDivider={searchResult.withBottomDivider}
                  isHighlight={isHighlight}
                >
                  {searchResult.searchItem}
                </SearchItemWrapper>
              </a>
            </Link>
          </>
        );
      })}
    </SearchResultWrapper>
  );
};

export default SearchResult;
