import React, { useContext } from 'react';
import styled from 'styled-components';
import { Search } from 'react-feather';
import { media } from 'theme';

import { DesktopOnly, MobileOnly } from 'components/misc/Layout';
import DesktopSearchBar from 'components/search/DesktopSearchBar';
import { SearchContext } from 'components/search/SearchProvider';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

const StyledSearchBar = styled.div`
  background: white;
  color: ${props => props.theme.colors.neutral5};
  padding: 10px 12px;
  display: flex !important;
  align-items: center;
  z-index: 1;
  border-radius: ${props => props.theme.borderRadius.md};

  > svg {
    color: ${props => props.theme.colors.neutral7};
    margin-right: 10px;
  }

  margin: 0 auto;
  width: 720px;

  ${media.largeAndDown`
    width: 100%;
  `}
`;

const StyledMagnifyIcon = styled(Search)`
  color: white;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const SearchBar = () => {
  const { setShowMobileResult } = useContext(SearchContext);

  return (
    <>
      <MobileOnly>
        <StyledSearchBar
          onClick={() => {
            setShowMobileResult(true);
            mixpanel.track(trackedEvents.HomePage, { param: 'searchbox' });
          }}
        >
          <StyledMagnifyIcon size={16} /> Search for articles, discussions and
          products
        </StyledSearchBar>
      </MobileOnly>
      <DesktopOnly>
        <DesktopSearchBar
          variant="home"
          onFocus={() => {
            mixpanel.track(trackedEvents.HomePage, { param: 'searchbox' });
          }}
        />
      </DesktopOnly>
    </>
  );
};

export default SearchBar;
