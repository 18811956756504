import React from 'react';
import { useRouter } from 'next/router';
import Button from 'app/seedly-component-library/button-new';
import Avatar from 'app/seedly-component-library/avatar-new';

import { MessageCircle } from 'react-feather';

import { useIsSignedIn } from 'entity/user/userHooks';
import formatTimeago from 'utils/formatTimeago';
import buildConfig from 'utils/buildConfig';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import JoinGroupCarousel from 'components/group/JoinGroupCarousel';
import BookmarkButton from 'components/post/BookmarkButton';
import { SAVE_TYPE } from 'constants/app';

import type { Post } from 'entity/post/postTypes';

import SectionHeader from '../../SectionHeader';

interface OwnProps {
  trendingPosts: Post[];
}

const TabContentCommunity = (props: OwnProps) => {
  const router = useRouter();
  const isSignedIn = useIsSignedIn();

  const { trendingPosts } = props;

  return (
    <div className="flex flex-col gap-4 w-full pt-4 lg:pr-0 pr-4">
      <SectionHeader emoji="🗣" emojiAriaLabel="person-speaking">
        Trending Discussions
      </SectionHeader>
      <div className="flex flex-col gap-2 items-center w-full">
        {trendingPosts.map(post => {
          return (
            <div
              className="w-full rounded-lg px-4 py-3 h-fit border-neutral-400 border border-solid"
              key={post.id}
            >
              <div className="flex flex-col gap-3">
                <div
                  className="flex items-center gap-1 cursor-pointer"
                  onClick={() => router.push(`/community/${post.group.slug}/`)}
                >
                  <Avatar src={post.group.profileImage} sizeClass="avatar-4" />
                  <p className="text-neutral-500">{post.group.name}</p>
                </div>
                <a
                  className="text-sm font-bold underline hover:text-blue-500"
                  href={`${buildConfig.apiHost}/posts/${post.slug}/`}
                  onClick={() => {
                    mixpanel.track(trackedEvents.HomePage, {
                      param: post.title,
                    });
                  }}
                >
                  {post.title}
                </a>
                <div className="flex gap-1 items-center w-full">
                  <div className="flex gap-1 items-center">
                    <p className="text-neutral-500">
                      {formatTimeago(post.createdAt)}
                    </p>
                    <p className="text-neutral-500">&#x2022;</p>
                    <div className="flex gap-1 items-center">
                      <MessageCircle size={16} className="text-neutral-500" />
                      <p className="text-neutral-500">{post.commentsCount}</p>
                    </div>
                  </div>
                  <BookmarkButton
                    size={20}
                    contentId={post.id}
                    saved={post.bookmarked}
                    slug={post.slug}
                    type={SAVE_TYPE.POST}
                    showIconOnly
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-center w-full pb-1">
        <Button
          variant="outline"
          onClick={() => {
            mixpanel.track(trackedEvents, {
              params: 'see_all_trending_discussions',
            });
            router.push({
              pathname: '/community/',
              query: { trending: true },
            });
          }}
        >
          See all trending discussions
        </Button>
      </div>
      {isSignedIn && (
        <JoinGroupCarousel
          title={
            <div className="flex gap-4 items-center">
              <span role="img" aria-label="fire">
                🔥
              </span>
              Trending Groups
            </div>
          }
          description="Discuss your thoughts with like-minded members in these community groups!"
          desktopSlidesToScroll={5}
          desktopSlidesToShow={5}
          trackedEvent={trackedEvents.HomePage}
        />
      )}
    </div>
  );
};

export default TabContentCommunity;
