import styled from 'styled-components';

import Space from '../space';
import spacing from '../themes/spacing';

export default styled(Space)<{ horizontalPadding?: keyof typeof spacing }>`
  overflow-x: scroll;
  max-width: 100%;
  padding: 0 ${props => spacing[props.horizontalPadding] || '0'};

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;
