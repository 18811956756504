import React, { Ref } from 'react';

import clsx from 'clsx';

interface OwnProps {
  refProp?: Ref<HTMLDivElement>;
  children: React.ReactNode;
  fullWidth?: boolean;
  wrapperClassName: string;
  contentClassName?: string;
}

const Section = ({
  refProp,
  fullWidth = false,
  children,
  wrapperClassName = '',
  contentClassName = '',
}: OwnProps) => {
  return (
    <div
      className={clsx(
        'flex items-center w-full justify-center mx-auto my-0',
        wrapperClassName,
      )}
      ref={refProp}
    >
      <div
        className={clsx(
          'flex items-center w-full',
          fullWidth ? 'lg:w-full' : 'lg:w-[1024px]',
          contentClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Section;
