import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ChevronDown } from 'react-feather';

import { Colors, Jumbotron } from '@component-lib';
import { homeImage, media } from 'theme';
import SearchBar from 'components/misc/SearchBar';
import { useIsSignedIn } from 'entity/user/userHooks';

import type { RootState } from 'reducer';

const StyledJumbotron = styled(Jumbotron)`
  position: relative;
  min-height: 264px;

  ${media.largeAndDown`
    min-height: 193px;
  `};
`;

interface OwnProps {
  onActionClick?: () => void;
}

const HomeContentBanner = (props: OwnProps) => {
  const isSignedin = useIsSignedIn();
  const userProfileName = useSelector(
    (state: RootState) => state.auth.user?.name,
  );

  return (
    <StyledJumbotron
      desktopBackground={homeImage.bannerDesktop}
      mobileBackground={homeImage.bannerMobile}
    >
      <div className="flex flex-col gap-4 w-full h-full justify-center items-center">
        <p className="text-2xl font-bold text-white">
          {!isSignedin ? (
            'Welcome to Seedly!'
          ) : (
            <>
              Hello, {userProfileName}{' '}
              <span role="img" aria-label="smiley-face">
                😀
              </span>
            </>
          )}
        </p>
        <SearchBar />
        {!isSignedin && (
          <div
            className="flex flex-col items-center justify-center absolute bottom-0 lg:bottom-3 cursor-pointer"
            onClick={props.onActionClick}
          >
            <p className="text-white text-center">What is Seedly?</p>
            <ChevronDown size={20} color={Colors.neutral1} />
          </div>
        )}
      </div>
    </StyledJumbotron>
  );
};

export default HomeContentBanner;
