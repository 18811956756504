import styled from 'styled-components';
import { media } from 'theme';
import colors from '../themes/colors';

export const Wrapper = styled.div<{
  heightProp: string;
  backgroundColor: string;
  desktopBackground: string;
  mobileBackground: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({ heightProp }) => heightProp};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${props => props.backgroundColor || colors.seedlyBlue};
  background-image: ${props => props.desktopBackground};
  ${props => media.largeAndDown`
      background-image: ${props.mobileBackground};
  `}
`;
