import React from 'react';
import { useRouter } from 'next/router';
import Button from 'app/seedly-component-library/button-new';

import SignUpBonusCard from 'components/review/SignUpBonusCard';
import SectionHeader from '../../SectionHeader';
import CategoryCard from './CategoryCard';

import type { PopularCategory } from '../../types';

interface OwnProps {
  popularCategories: PopularCategory[];
}

const TabContentReviews = (props: OwnProps) => {
  const router = useRouter();
  const { popularCategories } = props;

  return (
    <div className="flex flex-col gap-4 w-full pt-4 pr-3 lg:pr-0">
      <SectionHeader emoji="👍🏻" emojiAriaLabel="thumb-up">
        Popular Product Categories
      </SectionHeader>
      {/* display: grid doesn't work well with safari. a workaround is to have an empty div wrapping it */}
      <div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 content-start gap-3">
          <SignUpBonusCard
            TitleComponent={
              <>
                Get amazing deals when you sign up for new financial products on
                Seedly 🎁
              </>
            }
            ctaText="See All Offers"
            onCTAClick={() => {
              router.push('/reviews/sign-up-bonus/');
            }}
          />
          {popularCategories.map(category => {
            return <CategoryCard key={category.id} category={category} />;
          })}
        </div>
      </div>
      <div className="flex w-full items-center justify-center">
        <Button variant="outline" onClick={() => router.push('/reviews')}>
          See all products
        </Button>
      </div>
    </div>
  );
};

export default TabContentReviews;
