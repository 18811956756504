import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import OverflowScroll from '../overflowScroll';

import TabPane from './TabPane';

const Container = styled.div`
  width: 100%;
`;
interface OwnProps {
  items: {
    activeIcon?: React.ReactNode;
    inactiveIcon?: React.ReactNode;
    label: string;
    value: string;
    children?: React.ReactNode;
  }[];
  defaultActiveKey?: String;
  activeKey?: string;
  onChange?: (key: string) => void;
}

const Tabs = React.forwardRef<HTMLDivElement, OwnProps>(
  (props: OwnProps, ref) => {
    const { items, defaultActiveKey, activeKey, onChange } = props;

    const [innerActiveKey, setActiveKey] = useState(
      defaultActiveKey || items[0].value,
    );

    const isValidKey = useMemo(() => items.find(i => i.value === activeKey), [
      activeKey,
      items,
    ]);

    const usedActiveKey = isValidKey ? activeKey : innerActiveKey;

    return (
      <Container ref={ref}>
        <OverflowScroll size="lg">
          {items.map(item => {
            return (
              <TabPane
                key={item.value}
                label={item.label}
                isActive={item.value === usedActiveKey}
                activeIcon={item.activeIcon}
                inactiveIcon={item.inactiveIcon}
                onClick={() => {
                  setActiveKey(item.value);
                  if (onChange) {
                    onChange(item.value);
                  }
                }}
              />
            );
          })}
        </OverflowScroll>
        {items.find(i => i.value === usedActiveKey)?.children}
      </Container>
    );
  },
);

export default Tabs;
