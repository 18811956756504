import React from 'react';
import { MessageCircle } from 'react-feather';
import NextImage from 'next/image';

import formatTimeago from 'utils/formatTimeago';
import buildConfig from 'utils/buildConfig';
import BookmarkButton from 'components/post/BookmarkButton';
import { SAVE_TYPE } from 'constants/app';

import type { Article } from 'entity/blog/blogTypes';

interface OwnProps {
  article: Article;
}

const ArticleCard = (props: OwnProps) => {
  const { article } = props;

  return (
    <div className="bg-white rounded-lg w-full h-full p-4 pt-3 border-neutral-400 border border-solid">
      <div className="flex flex-col gap-3 w-full justify-between h-full">
        <div className="pt-1">
          <NextImage
            className="rounded-md"
            src={article.imageUrl}
            alt={article.imageAlt}
            layout="intrinsic"
            width={660}
            height={346.5}
          />
        </div>
        <div className="flex flex-col gap-2 w-full h-full">
          <div className="flex flex-col gap-2 h-full items-center">
            <div className="flex justify-between w-full">
              <p className="text-xs font-bold uppercase text-blue-500">
                {article.categories[0].name.toUpperCase()}
              </p>
              <BookmarkButton
                size={20}
                contentId={article.id}
                slug={article.slug}
                type={SAVE_TYPE.ARTICLE}
                showIconOnly
              />
            </div>
            <a
              className="text-base underline"
              href={`${buildConfig.blogHost}/${article.slug}`}
            >
              {article.title}
            </a>
          </div>
          <div className="flex gap-1 items-center">
            <p className="text-neutral-500">{article.author.name}</p>
            <p className="text-neutral-500">&#x2022;</p>
            <p className="text-neutral-500">
              {formatTimeago(article.publishDate)}
            </p>
            <p className="text-neutral-500">&#x2022;</p>
            <div className="flex gap-1 items-center">
              <MessageCircle size={16} className="text-neutral-500" />
              <p className="text-neutral-500">{article.commentsCount || 0}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
