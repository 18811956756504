import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import Button from 'app/seedly-component-library/button-new';
import { RootState } from 'reducer';
import { getRewardsRequest } from 'entity/reward/rewardRedux';

import RewardItem from 'app/pageContents/RewardsContent/RewardItem';

import SectionHeader from '../../SectionHeader';

const TabContentRewards = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const rewardIds = useSelector((state: RootState) => state.reward.ids);
  const rewardById = useSelector((state: RootState) => state.reward.byId);

  useEffect(() => {
    dispatch(
      getRewardsRequest({
        sort_by_featured: true,
        per: 8,
        page: 1,
      }),
    );
  }, [dispatch]);

  const rewards = rewardIds.map(id => {
    return rewardById[id];
  });

  return (
    <div className="flex flex-col gap-4 w-full pt-4 pr-3 lg:pr-0">
      <SectionHeader emoji="🎁" emojiAriaLabel="gift">
        Limited Time Rewards
      </SectionHeader>
      <div className="w-full grid lg:grid-cols-4 grid-cols-2 gap-3 ">
        {rewards.map(reward => {
          return (
            <RewardItem
              key={reward.id}
              reward={reward}
              canRedeem
              claim={null}
              desktopWidth="240px"
              onClick={() => {
                router.push(`/rewards/?id=${reward.id}`);
              }}
            />
          );
        })}
      </div>
      <div className="flex items-center w-full justify-center gap-4">
        <Button variant="outline" onClick={() => router.push('/rewards')}>
          See all rewards
        </Button>
      </div>
    </div>
  );
};

export default TabContentRewards;
