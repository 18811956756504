import React from 'react';
import { useRouter } from 'next/router';

import Button from 'app/seedly-component-library/button-new';

import type { Article } from 'entity/blog/blogTypes';

import SectionHeader from '../../SectionHeader';
import ArticleCard from './ArticleCard';

interface OwnProps {
  trendingArticles: Article[];
}

const TabContentBlog = (props: OwnProps) => {
  const router = useRouter();
  const { trendingArticles } = props;

  return (
    <div className="flex flex-col w-full gap-4 pt-4 lg:pr-0 pr-3">
      <SectionHeader emoji="🗣" emojiAriaLabel="person-speaking">
        Latest Articles
      </SectionHeader>
      <div className="grid gap-3 grid-cols-1 lg:grid-cols-2">
        {trendingArticles.slice(0, 2).map(article => {
          return <ArticleCard key={article.id} article={article} />;
        })}
      </div>
      <div className="grid gap-3 grid-cols-1 lg:grid-cols-3">
        {trendingArticles.slice(2, 5).map(article => {
          return <ArticleCard key={article.id} article={article} />;
        })}
      </div>
      <div className="flex w-full items-center justify-center gap-4">
        <Button variant="outline" onClick={() => router.push('/blog')}>
          See all articles
        </Button>
      </div>
    </div>
  );
};

export default TabContentBlog;
