import React, { useEffect, useCallback } from 'react';

const useClickOutside = (
  target: React.RefObject<HTMLElement>,
  handleCloseAction: () => void,
) => {
  const handleClickOutside = useCallback(
    e => {
      if (!target.current.contains(e.target)) {
        handleCloseAction();
      }
    },
    [target, handleCloseAction],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

export default useClickOutside;
