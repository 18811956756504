import React from 'react';
import { useRouter } from 'next/router';
import { OverflowScroll } from '@component-lib';
import { Compass, MoreHorizontal } from 'react-feather';
import Avatar from 'app/seedly-component-library/avatar-new';

import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { useSelectFollowedGroupListByUnreadCount } from 'entity/group/groupHooks';
import { useIsSignedIn } from 'entity/user/userHooks';

import type { Group } from 'entity/group/groupRedux';

import SectionHeader from '../SectionHeader';

interface OwnProps {
  trendingGroups: Group[];
}

const GroupsContent = (props: OwnProps) => {
  const router = useRouter();
  const isSignedIn = useIsSignedIn();
  const followedGroups = useSelectFollowedGroupListByUnreadCount();

  const { trendingGroups = [] } = props;

  const groupsToShow = isSignedIn ? followedGroups.slice(0, 8) : trendingGroups;

  const showJoinGroupsPrompt = isSignedIn && followedGroups.length === 0;

  const trackGroupClick = (groupName: string) => {
    mixpanel.track(trackedEvents.HomePage, {
      params: `group-${groupName.replace(/-/g, '_')}`,
    });
  };

  return (
    <div className="flex flex-col w-full gap-2">
      <SectionHeader emoji="👥" emojiAriaLabel="people" indent>
        {isSignedIn ? 'My Groups' : 'Trending Groups'}
      </SectionHeader>
      <div className="px-0 py-4 rounded bg-white h-fit w-full">
        <OverflowScroll
          align="start"
          justifyContent="start"
          horizontalPadding="md"
          direction="column"
        >
          {showJoinGroupsPrompt && (
            <p className="text-neutral-500">
              Join groups for a more personalised browsing experience
            </p>
          )}
          <div className="flex items-end space-x-6">
            {groupsToShow.map(group => (
              <div
                className="flex flex-col gap-2 cursor-pointer items-center relative"
                key={group.id}
                onClick={() => {
                  trackGroupClick(group.slug);
                  router.push(`/community/${group.slug}/`);
                }}
              >
                <Avatar
                  src={group.profileImage}
                  sizeClass="avatar-10"
                  badge={
                    group.unreadCount ? (
                      <div className="w-2 h-2 bg-blue-500 rounded-full absolute top-0 right-0" />
                    ) : null
                  }
                  badgePositionClass="absolute top-0 right-0"
                />
                <p className="whitespace-nowrap">{group.name}</p>
              </div>
            ))}
            {(!isSignedIn || followedGroups.length === 0) && (
              <div
                className="flex flex-col gap-2 cursor-pointer items-center relative"
                onClick={() => {
                  trackGroupClick('discover-groups');
                  router.push({
                    pathname: '/community/',
                    query: { discover: true },
                  });
                }}
              >
                <Compass size={40} />
                <p className="whitespace-nowrap">Discover Groups</p>
              </div>
            )}
            {isSignedIn && followedGroups.length > 8 && (
              <div
                className="flex flex-col gap-2 cursor-pointer items-center relative"
                onClick={() => {
                  trackGroupClick('more-updates');
                  router.push({
                    pathname: '/community/',
                    query: { discover: true },
                  });
                }}
              >
                <MoreHorizontal size={40} />
                <p className="whitespace-nowrap">More updates</p>
              </div>
            )}
          </div>
        </OverflowScroll>
      </div>
    </div>
  );
};

export default GroupsContent;
