import round from 'lodash/round';
import { register } from 'timeago.js';

const DEFAULT_LOCALE = 'default-locale';

const defaultLocale = (number, index, totalSec) => {
  const MONTH_IN_SECONDS = 2592000;
  const monthCount = round(totalSec / MONTH_IN_SECONDS, 0);
  return [
    ['just now'],
    ['1m ago'],
    ['1m ago'],
    ['%sm ago'],
    ['%sh ago'],
    ['%sh ago'],
    ['1d ago'],
    ['%sd ago'],
    ['1w ago'],
    ['%sw ago'],
    ['1mth ago'],
    ['%smth ago'],
    [`${monthCount}mth ago`],
    [`${monthCount}mth ago`],
  ][index];
};

register(DEFAULT_LOCALE, defaultLocale);

const locales = {
  [DEFAULT_LOCALE]: defaultLocale,
};

export const localeKeys = {
  DEFAULT_LOCALE,
};

export default locales;
