import React, { useRef } from 'react';

import { useIsSignedIn } from 'entity/user/userHooks';
import AdSlot from 'app/components/misc/AdSlot';
import { useScrollRestoration } from 'utils/hooks/useScrollRestoration';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

import type { Group } from 'entity/group/groupRedux';
import type { Post } from 'entity/post/postTypes';
import type { Article } from 'entity/blog/blogTypes';

import Banner from './Banner';
import Section from './Section';
import GroupsContent from './GroupsContent';
import PopularContent from './PopularContent';
import TabContent from './TabContent';
import AboutSeedlyContent from './AboutSeedlyContent';
import SEOContent from './SEOContent';

import type { PopularCategory, SEOSection } from './types';

export type { PopularCategory, SEOSection } from './types';

interface OwnProps {
  trendingArticles: Article[];
  trendingGroups: Group[];
  trendingPosts: Post[];
  popularItems: (Article | Post)[];
  popularCategories: PopularCategory[];
  seoSections: SEOSection[];
}

const Home = (props: OwnProps) => {
  useScrollRestoration();

  const {
    trendingArticles,
    trendingGroups,
    popularItems,
    trendingPosts,
    popularCategories,
    seoSections,
  } = props;

  const aboutSeedlyRef = useRef(null);
  const isSignedIn = useIsSignedIn();

  const handleBannerActionClick = () => {
    mixpanel.track(trackedEvents.HomePage, { param: 'whats_seedly' });
    const { top } = aboutSeedlyRef.current.getBoundingClientRect();
    const topWindow = window.scrollY;
    window.scrollTo({ top: top - 56 + topWindow, behavior: 'smooth' }); // account for header height
  };

  return (
    <div className="flex flex-col w-full items-center">
      <Banner onActionClick={handleBannerActionClick} />
      <Section
        wrapperClassName="px-0 py-10 bg-transparent"
        contentClassName="flex-col gap-6 "
      >
        <GroupsContent trendingGroups={trendingGroups} />
        <PopularContent popularItems={popularItems} />
        <AdSlot type="home" slotKey="leaderboard" />
      </Section>
      <Section wrapperClassName="pt-4 pr-0 pb-6 pl-4 bg-neutral-100">
        <TabContent
          trendingPosts={trendingPosts}
          trendingArticles={trendingArticles}
          popularCategories={popularCategories}
        />
      </Section>
      {!isSignedIn && (
        <>
          <Section
            refProp={aboutSeedlyRef}
            wrapperClassName="px-4 py-10 bg-blue-500"
          >
            <AboutSeedlyContent />
          </Section>
          <Section wrapperClassName="px-4 py-10 bg-blue-100">
            <SEOContent seoSections={seoSections} />
          </Section>
        </>
      )}
    </div>
  );
};

export default Home;
