import React from 'react';
import { Tooltip } from '@component-lib';
import NextImage from 'next/image';
import { useRouter } from 'next/router';

import buildConfig from 'utils/buildConfig';

import type { PopularCategory } from '../../../types';

interface OwnProps {
  category: PopularCategory;
}

const CategoryCard = (props: OwnProps) => {
  const { category } = props;
  const router = useRouter();

  return (
    <div className="bg-white border-neutral-400 border border-solid px-4 py-3 w-full rounded-lg h-fit cursor-pointer">
      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-col w-full">
          <a
            className="font-bold text-base underline hover:text-blue-500"
            href={`${buildConfig.apiHost}/reviews/${category.slug}`}
          >
            {category.name}
          </a>
          <p className="text-neutral-500">{category.productCount} products</p>
        </div>
        <div className="flex gap-2 w-full">
          {category.popularProducts.map(product => {
            const ProductCard = () => (
              <div
                className="bg-white border-neutral-400 border border-solid p-2 w-full rounded-lg h-fit cursor-pointer"
                onClick={() => {
                  router.push(
                    `${buildConfig.apiHost}/reviews/${category.slug}/${product.slug}`,
                  );
                }}
              >
                <NextImage
                  src={product.logo}
                  alt={product.name}
                  height={40}
                  width={40}
                  layout="responsive"
                />
              </div>
            );

            return (
              <div className="w-full" key={product.id}>
                <div className="mobile-only">
                  <ProductCard />
                </div>
                <div className="desktop-only">
                  <Tooltip
                    key={product.id}
                    showToolTip
                    placement="top"
                    triggerStyle={{ width: '100%' }}
                    renderTriggerButton={ProductCard}
                    renderToolTipBody={() => <div>{product.name}</div>}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
