import styled from 'styled-components';
import { media } from 'theme';

interface SearchWrapper {
  isHighlight: boolean;
  withBottomDivider?: boolean;
}

export const SearchResultWrapper = styled.div<{ width: string }>`
  position: absolute;
  padding-top: ${props => props.theme.spacing.sm};
  padding-bottom: ${props => props.theme.spacing.sm};

  background-color: white;
  border-radius: ${props => props.theme.borderRadius.md};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  width: ${props => props.width};
  height: fit-content;

  ${media.largeAndDown`
    width: 100%;
    position: relative;
  `};
`;

export const SearchItemWrapper = styled.div<SearchWrapper>`
  display: flex;
  align-items: center;
  padding: ${props => `${props.theme.spacing.xs} ${props.theme.spacing.md}`};
  cursor: pointer;
  background-color: ${props =>
    props.isHighlight ? props.theme.colors.neutral3 : 'white'};

  border-bottom: ${props =>
    props.withBottomDivider && `1px solid ${props.theme.colors.neutral2}`};

  :hover {
    background-color: ${props => props.theme.colors.neutral3};
  }
`;

export const SearchTitleWrapper = styled.div`
  padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.md}`};
`;
