import React, { useMemo } from 'react';
import {
  Reward,
  RewardClaim,
  TagType,
  TierMap,
} from 'entity/reward/rewardTypes';
import { mapTagType } from 'entity/reward/rewardUtils';
import useIsMobile from 'utils/hooks/useIsMobile';
import useWindowWidth from 'utils/hooks/useWindowWidth';
import { openRewardModal } from 'dispatcher/modalDispatcher';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import clsx from 'clsx';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import LabelTag from './LabelTag';

interface OwnProps {
  reward: Reward;
  canRedeem: boolean;
  claim: RewardClaim;
  desktopWidth?: string;
  onClick?: () => void;
}

const RewardRequirement = (props: {
  reward: Reward;
  tagType: TagType;
  claim: RewardClaim;
}) => {
  const { reward, tagType, claim } = props;
  const isMobile = useIsMobile();

  if (tagType === 'redeemed') {
    if (!reward.endedAt) return null;
    const validTillDate = new Date(reward.endedAt);
    return (
      <p className="text-xs font-bold text-neutral-500">
        Valid till {format(validTillDate, 'd MMM yyyy')}
      </p>
    );
  }

  if (tagType === 'used' && claim) {
    const usedAtDate = new Date(claim.used_at);
    return (
      <p className="text-xs font-bold text-neutral-500">
        Used on {format(usedAtDate, 'd MMM yyyy')}
      </p>
    );
  }

  if (tagType === 'expired') {
    const endedAt = new Date(reward.endedAt);
    return (
      <p className="text-xs font-bold text-neutral-500">
        Expired on {format(endedAt, 'd MMM yyyy')}
      </p>
    );
  }

  return isMobile ? (
    <p className="text-xs font-bold text-neutral-500">
      {reward.minTier > 1 && `${TierMap[reward.minTier]}`}
      <br />
      {reward.minCredits?.toLocaleString()} Credits
    </p>
  ) : (
    <div className="flex items-center gap-2">
      {reward.minTier > 1 && (
        <>
          <p className="text-xs font-bold text-neutral-500">
            {`${TierMap[reward.minTier]}`}
          </p>
          <div className="vertical-divider h-3 border-neutral-500" />
        </>
      )}
      <p className="text-xs font-bold text-neutral-500">
        {reward.minCredits?.toLocaleString()} Credits
      </p>
    </div>
  );
};

const RewardItem = (props: OwnProps) => {
  const { reward, canRedeem, claim, onClick, desktopWidth = '281px' } = props;
  const tagType = mapTagType(reward, canRedeem, claim);
  const isMobile = useIsMobile();
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();

  const cardWidth = useMemo(() => {
    // for desktop
    if (!isMobile) return desktopWidth;

    // for mobile, 32px is the left + right padding and gap between.
    // divide by 2 for each button
    const availableWidth = (windowWidth - 48) / 2;
    return `${availableWidth}px`;
  }, [windowWidth, isMobile, desktopWidth]);

  return (
    <div
      className="flex flex-col cursor-pointer items-center "
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          dispatch(openRewardModal(reward.id, claim?.id));
          mixpanel.track(trackedEvents.RewardsCollection, {
            param: reward.title.toLowerCase().split(' ').join('_'),
          });
        }
      }}
    >
      <div
        className="rounded-lg h-[120px] relative rounded-b-none "
        style={{ width: cardWidth }}
      >
        <img
          className={clsx(
            'object-cover rounded-t-lg h-[120px]',
            (tagType === 'fully_redeemed' ||
              tagType === 'lock' ||
              tagType === 'used') &&
              'opacity-50',
          )}
          style={{ width: cardWidth }}
          alt="banner"
          src={reward.banner}
        />
        <div className="absolute top-2 right-2">
          <LabelTag tagType={tagType} />
        </div>
      </div>
      <div
        className="rounded-lg bg-white p-2 lg:p-3 rounded-t-none h-[120px] lg:h-[104px] shadow"
        style={{ width: cardWidth }}
      >
        <div className="flex flex-col h-full justify-between">
          <p className="text-sm font-bold">{reward.title}</p>
          <RewardRequirement tagType={tagType} reward={reward} claim={claim} />
        </div>
      </div>
    </div>
  );
};

export default RewardItem;
