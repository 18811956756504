import React from 'react';
import styled from 'styled-components';
import Text from '../text';

import colors from '../themes/colors';

const StyledContainer = styled.div<{ bgColor: string }>`
  width: fit-content;
  padding: ${props => `${props.theme.spacing.xxs} ${props.theme.spacing.xs}`};
  border-radius: ${props => props.theme.borderRadius.lg};
  background: ${props => props.bgColor};
`;

interface OwnProps {
  bgColor?: string;
  children: React.ReactNode;
  className?: string;
}

const LabelTag = ({
  bgColor = colors.seedlyBlue,
  children,
  className,
  ...rest
}: OwnProps) => {
  return (
    <StyledContainer
      className={className}
      bgColor={colors[bgColor] || bgColor}
      {...rest}
    >
      <Text intent="inverted" variant="overlinesm">
        {children}
      </Text>
    </StyledContainer>
  );
};

export default LabelTag;
