import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import { format } from 'timeago.js';
import { localeKeys } from './locales';

const formatTimeago = (rawDate, options = {}) => {
  const locale = options.locale || localeKeys.DEFAULT_LOCALE;
  const prefix = options.prefix || '';
  const cutoff = isNil(options.cutoff) ? true : options.cutoff;
  const date = new Date(rawDate);

  try {
    if (isNil(rawDate) || Number.isNaN(date)) return '';

    if (!cutoff) return format(date, locale);

    const lastDayOfMonth = dayjs(rawDate).add(1, 'month');

    if (dayjs().isBefore(lastDayOfMonth)) {
      return format(date, locale);
    }

    return `${prefix}${dayjs(date).format('DD MMM YYYY')}`;
  } catch (error) {
    return '';
  }
};

export default formatTimeago;
