import React from 'react';
import styled from 'styled-components';

import Text from '../text';

const TabPaneContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing.xs} 0;
  cursor: pointer;
`;

const TabPaneLabel = styled(Text)`
  margin-left: ${props => props.theme.spacing.xs} !important;
`;

const ActiveBorder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: ${props => props.theme.colors.seedlyBlue};
  width: 100%;
  margin: 0;
`;

interface OwnProps {
  activeIcon?: React.ReactNode;
  inactiveIcon?: React.ReactNode;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
}

const TabPane = (props: OwnProps) => {
  const {
    isActive = false,
    activeIcon = null,
    inactiveIcon = null,
    label,
    onClick,
  } = props;

  return (
    <TabPaneContainer onClick={onClick}>
      {isActive ? activeIcon : inactiveIcon}
      <TabPaneLabel
        variant="titlemd"
        whiteSpace="nowrap"
        intent={isActive ? 'primary' : 'secondary'}
      >
        {label}
      </TabPaneLabel>
      {isActive && <ActiveBorder />}
    </TabPaneContainer>
  );
};

export default TabPane;
