import React from 'react';
import { Lock } from 'react-feather';
import { TagType } from 'entity/reward/rewardTypes';

interface OwnProps {
  tagType: TagType;
}

const LabelTag = (props: OwnProps) => {
  const { tagType } = props;

  if (tagType === 'lock') {
    return (
      <div className="p-1 w-fit rounded-full bg-neutral-700">
        <Lock size={16} color="white" />
      </div>
    );
  }

  if (tagType === 'featured') {
    return (
      <div className="p-1 w-fit rounded bg-neutral-100">
        <p className="text-[10px] font-bold uppercase text-blue-500">
          <span role="img" aria-label="fire">
            🔥
          </span>
          FEATURED
        </p>
      </div>
    );
  }

  if (tagType === 'fully_redeemed') {
    return (
      <div className="p-1 w-fit rounded bg-red-500">
        <p className="text-[10px] font-bold uppercase text-white">
          FULLY REDEEMED
        </p>
      </div>
    );
  }

  if (tagType === 'redeemed') {
    return (
      <div className="p-1 w-fit rounded bg-green-500">
        <p className="text-[10px] font-bold uppercase text-white">REDEEMED</p>
      </div>
    );
  }

  if (tagType === 'used') {
    return (
      <div className="p-1 w-fit rounded bg-neutral-700">
        <p className="text-[10px] font-bold uppercase text-white">USED</p>
      </div>
    );
  }

  if (tagType === 'expired') {
    return (
      <div className="p-1 w-fit rounded bg-neutral-700">
        <p className="text-[10px] font-bold uppercase text-white">EXPIRED</p>
      </div>
    );
  }

  return null;
};

export default LabelTag;
