import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { createLocalSearch } from 'constants/searchComparison';
import useClickOutside from 'utils/hooks/useClickOutside';
import SearchInput from '../SearchInput';
import SearchResult from '../SearchResult';
import { SearchContext } from '../SearchProvider';
import useSearchResults from '../SearchProvider/useSearchResults';

const SearchBarWrapper = styled.div<{ width: string }>`
  width: ${props => props.width};
  position: relative;
  z-index: 1;
`;

interface OwnProps {
  variant?: 'home' | 'header';
  onFocus?: () => void;
}

const SearchBarTheme = {
  header: {
    width: '400px',
  },
  home: {
    width: '760px',
  },
};

const DesktopSearchBar = (props: OwnProps) => {
  const { variant = 'header', onFocus = () => {} } = props;

  const { searchTerm, isInputFocus } = useContext(SearchContext);

  const [showResult, setShowResult] = useState<boolean>(false);

  // cursor handling highlight on the result list for desktop
  const [cursor, setCursor] = useState<number>(-1);

  // result component list
  const resultList = useSearchResults();

  const searchWrapper = useRef(null);
  const localSearch = useRef(createLocalSearch());
  const router = useRouter();

  useClickOutside(searchWrapper, () => setShowResult(false));

  // if focused on the input, show search result
  useEffect(() => {
    if (isInputFocus) {
      setShowResult(true);
      if (onFocus) onFocus();
    }
  }, [isInputFocus, onFocus]);

  // handling keypress once search
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          if (cursor < resultList.length - 1) {
            setCursor(prev => prev + 1);
          } else {
            setCursor(0);
          }
          break;
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          if (cursor < 1) {
            setCursor(resultList.length - 1);
          } else {
            setCursor(prev => prev - 1);
          }
          break;
        case 'Enter':
          // when cursor is -1, is on the search bar itself.
          if (cursor === -1) {
            router.push(`/search?s=${searchTerm}`);
          } else {
            router.push(resultList[cursor]?.link);
            if (resultList[cursor]?.item) {
              localSearch.current.save(resultList[cursor]?.item);
            }
            setShowResult(false);
          }
          break;
        default:
      }
    },
    [cursor, resultList, router, localSearch, searchTerm],
  );

  const searchBarTheme = SearchBarTheme[variant];

  return (
    <SearchBarWrapper
      width={searchBarTheme.width}
      ref={searchWrapper}
      onKeyDown={handleKeyDown}
    >
      <SearchInput variant={variant} />
      {showResult && (
        <SearchResult
          width={searchBarTheme.width}
          searchTerm={searchTerm}
          resultList={resultList}
          onResultClick={() => setShowResult(false)}
          cursor={cursor}
        />
      )}
    </SearchBarWrapper>
  );
};

export default DesktopSearchBar;
