import React from 'react';
import styled from 'styled-components';
import colors from '../themes/colors';
import Button from './index';

const ExtendButton = styled(Button)`
  svg {
    margin-top: 4px;
  }

  &:hover {
    path:first-child {
      fill: ${props => props.bookmarked && colors.seedlyBlue};
    }
  }
`;

const BareContainer = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  cursor: pointer;
`;

const IconSvg = props => {
  const { size, bookmarked, color = colors.neutral7 } = props;

  return (
    <svg
      width={size}
      height={size}
      stroke={color}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 17.5L9.99984 13.3333L4.1665 17.5V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H14.1665C14.6085 2.5 15.0325 2.67559 15.345 2.98816C15.6576 3.30072 15.8332 3.72464 15.8332 4.16667V17.5Z"
        fill={bookmarked ? colors.neutral7 : 'transparent'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 6.6665L8.75 9.99984L7.5 8.88873"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BookmarkButtonBase = props => {
  const { bookmarked, showIconOnly, ...otherProps } = props;

  if (showIconOnly) {
    return (
      <BareContainer {...otherProps}>
        <IconSvg bookmarked={bookmarked} {...otherProps} />
      </BareContainer>
    );
  }

  return (
    <ExtendButton
      icon={<IconSvg bookmarked={bookmarked} />}
      bookmarked={bookmarked}
      {...otherProps}
    />
  );
};

export default BookmarkButtonBase;
