import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Message } from 'seedly-component-library';
import { get } from 'lodash';
import ProfileAction from 'reducer/profileRedux';
import blogApi from '../blogApi';

const useBookmarkArticle = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (articleSlug: string) => {
      const bookmarkResponse = await blogApi.bookmarkArticle(articleSlug);
      if (bookmarkResponse.ok) {
        const successMsg = get(bookmarkResponse, 'data.message', null);
        Message.success(successMsg);
        dispatch(
          ProfileAction.bookmarkArticleProfileSuccess(
            bookmarkResponse.data.data,
          ),
        );
      }
    },
    [dispatch],
  );
};

export default useBookmarkArticle;
