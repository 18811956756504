import React from 'react';
import { Carousel, Colors, OverflowScroll } from '@component-lib';
import Avatar from 'app/seedly-component-library/avatar-new';
import truncate from 'lodash/truncate';

import TrendingCard from 'components/misc/TrendingCard';

import formatTimeago from 'utils/formatTimeago';
import buildConfig from 'utils/buildConfig';

import type { Post } from 'entity/post/postTypes';
import type { Article } from 'entity/blog/blogTypes';

import SectionHeader from '../SectionHeader';

interface OwnProps {
  popularItems: (Article | Post)[];
}

const getTrendingCardProps = (
  post: Post,
): {
  HeaderComponent: React.ReactNode;
  href: string;
  bookmarkType: 'POST' | 'OPINION';
} => {
  let HeaderComponent = null;
  let href = '';
  let bookmarkType;

  if (post.type === 'Community::Opinion::Entity') {
    HeaderComponent = (
      <p className="uppercase text-xs font-bold text-neutral-500">OPINIONS</p>
    );
    href = `${buildConfig.apiHost}/opinions/${post.slug}`;
    bookmarkType = 'OPINION';
  } else if (post.type === 'Community::Question::Entity') {
    HeaderComponent = (
      <div className="flex gap-1 items-center">
        <Avatar src={post.group.profileImage} sizeClass="avatar-4" />
        <p className="uppercase text-xs  text-neutral-500">{post.group.name}</p>
      </div>
    );
    href = `${buildConfig.apiHost}/posts/${post.slug}`;
    bookmarkType = 'POST';
  }

  return {
    HeaderComponent,
    href,
    bookmarkType,
  };
};

const PopularContent = (props: OwnProps) => {
  const { popularItems } = props;

  const dataSource = popularItems.map(popularItem => {
    const truncatedTitle = truncate(popularItem.title, {
      length: 80,
      separator: ' ',
    });

    if ('objectID' in popularItem) {
      return (
        <TrendingCard
          key={popularItem.id}
          postId={popularItem.id}
          postSlug={popularItem.slug}
          HeaderComponent={
            <p className="uppercase text-xs font-bold text-neutral-500">
              ON {popularItem.categories[0].name.toUpperCase()}
            </p>
          }
          title={truncatedTitle}
          titleHref={`${buildConfig.apiHost}/blog/${popularItem.slug}`}
          tagColor={Colors.seedlyBlue}
          tagText="BLOG"
          dayAgoText={formatTimeago(popularItem.publishDate)}
          numberOfCommentsText={`${popularItem.commentsCount || 0}`}
          bookmarkType="ARTICLE"
          bookmarked={false}
        />
      );
    }

    const { HeaderComponent, href, bookmarkType } = getTrendingCardProps(
      popularItem,
    );

    return (
      <TrendingCard
        key={popularItem.id}
        postId={popularItem.id}
        postSlug={popularItem.slug}
        HeaderComponent={HeaderComponent}
        title={truncatedTitle}
        titleHref={href}
        tagColor={Colors.green5}
        tagText="COMMUNITY"
        dayAgoText={formatTimeago(popularItem.createdAt)}
        numberOfCommentsText={`${popularItem.commentsCount || 0}`}
        bookmarkType={bookmarkType}
        bookmarked={popularItem.bookmarked}
      />
    );
  });

  return (
    <div className="flex flex-col gap-2 w-full">
      <SectionHeader emoji="🔥" emojiAriaLabel="fire" indent>
        Popular on Seedly
      </SectionHeader>
      <div className="desktop-only w-full">
        <Carousel
          className="h-[184px]"
          cellSpacing={6}
          slidesToShow={5}
          slideToScroll={3}
          dataSource={dataSource.map(node => (
            <div
              className="flex items-center gap-4 mt-3 px-4 lg:px-0"
              key={node.key}
            >
              {node}
            </div>
          ))}
        />
      </div>
      <div className="mobile-only w-full">
        <OverflowScroll>
          <div className="flex items-center gap-2 mt-3 px-4 lg:px-0">
            {dataSource}
          </div>
        </OverflowScroll>
      </div>
    </div>
  );
};

export default PopularContent;
