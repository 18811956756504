import React, { useState } from 'react';
import { Carousel } from '@component-lib';
import NextImage from 'next/image';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

import buildConfig from 'utils/buildConfig';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import ArticleCard from 'app/pageContents/HomeContent/TabContent/TabContentBlog/ArticleCard';

import Pagination from 'components/misc/BasePage/RewardOnboardModal/pagination';

import type { SEOSection } from '../types';

interface OwnProps {
  seoSections: SEOSection[];
}

const Link = props => {
  return (
    <a
      className="text-sm text-blue-500 font-bold"
      hoverable
      onClick={() => {
        mixpanel.track(trackedEvents.HomePage, {
          param: `text_${props.children.replace(/ /g, '_')}`,
        });
      }}
      {...props}
    >
      {props.children}
    </a>
  );
};

const Paragraph = props => {
  return (
    <p className="text-center" {...props}>
      {props.children}
    </p>
  );
};

const SEOContent = (props: OwnProps) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const dataSource = props.seoSections.map(section => {
    return (
      <div
        className="p-6 rounded-lg w-full lg:w-auto h-[604px] bg-white "
        key={section.title}
      >
        <div className="flex flex-col h-full gap-4 items-center">
          {/* Space here to avoid distortion to image's fixed height due to the Space above */}
          {/* (next image doesn't work well with flex columns) */}
          <div className="flex items-center">
            <NextImage
              src={section.sectionIconSRC}
              width={64}
              height={64}
              layout="fixed"
              alt={section.title}
            />
          </div>
          <p className="text-base font-bold">{section.title}</p>
          <ReactMarkdown
            source={section.description.replace(
              /<apiHost>/g,
              buildConfig.apiHost,
            )}
            renderers={{
              link: Link,
              paragraph: Paragraph,
            }}
            plugins={[breaks]}
          />
          <ArticleCard article={section.article} />
        </div>
      </div>
    );
  });

  return (
    <div className="flex flex-col w-full gap-6 items-center">
      <p className="text-base font-bold text-center">
        Take charge of your personal finance! Begin your journey here:
      </p>
      <div className="flex flex-col gap-6 items-center justify-center w-full">
        <div className="mobile-only text-center w-full">
          <Carousel
            className="h-[604px]"
            slideIndex={slideIndex}
            afterSlide={index => setSlideIndex(index)}
            dataSource={dataSource}
            slidesToShow={1}
            slideToScroll={1}
          />
        </div>
        <div className="desktop-only text-center w-full">
          <Carousel
            className="h-[604px] w-[505px]"
            slideIndex={slideIndex}
            afterSlide={index => setSlideIndex(index)}
            dataSource={dataSource}
            slidesToShow={2}
            slideToScroll={2}
          />
        </div>
        <div className="mobile-only text-center w-full flex justify-center">
          <Pagination
            setPage={index => setSlideIndex(index)}
            totalPage={dataSource.length}
            currentIndex={slideIndex}
          />
        </div>
        <div className="desktop-only text-center w-full flex justify-center">
          <Pagination
            setPage={index => setSlideIndex(index * 2)}
            totalPage={dataSource.length / 2}
            currentIndex={slideIndex / 2}
          />
        </div>
      </div>
    </div>
  );
};

export default SEOContent;
