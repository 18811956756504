import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bookmark } from 'react-feather';
import { useAmp } from 'next/amp';

import { BookmarkButtonBase, Text } from 'app/seedly-component-library';

import { bookmarkOpinion } from 'app/dispatcher/opinionDispatcher';
import { openLoginModal } from 'app/dispatcher/modalDispatcher';

import { SAVE_TYPE } from 'app/constants/app';
import { LOGIN_MODAL, TYPES } from 'app/constants/login';
import buildConfig from 'app/utils/buildConfig';
import { bookmarkPostRequest } from 'entity/post/postRedux';
import { bookmarkCommentRequest } from 'entity/comment/commentRedux';
import useBookmarkArticle from 'app/entity/blog/hooks/useBookmarkArticle';

const BookmarkButton = props => {
  const {
    contentId,
    type,
    saved,
    size,
    slug,
    isText = false,
    commentableId,
    commentableType,
    showIconOnly = false,
    onClickTrack = () => {},
  } = props;
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const isSignedIn = useSelector(state => state.auth.session.isSignedIn);
  const { bookmarkedArticleIds } = useSelector(state => state.profile.user);
  const handleBookmarkArticle = useBookmarkArticle();
  const isAmp = useAmp();

  const handleIconFilled = isSaved => {
    if (
      Object.keys(user).length !== 0 &&
      type === SAVE_TYPE.ARTICLE &&
      bookmarkedArticleIds?.includes(contentId)
    ) {
      return true;
    }
    return !!isSaved;
  };

  const handleOnClick = typeParam => {
    let loginModalType;
    let bookmarkAction;
    switch (typeParam) {
      case SAVE_TYPE.ARTICLE:
        handleBookmarkArticle(slug);
        loginModalType = TYPES.SAVE_ARTICLE;
        break;
      case SAVE_TYPE.ANSWER:
        bookmarkAction = bookmarkCommentRequest;
        loginModalType = TYPES.SAVE_COMMENT;
        break;
      default:
      case SAVE_TYPE.OPINION:
        bookmarkAction = bookmarkOpinion;
        loginModalType = TYPES.SAVE_OPINION;
        break;
      case SAVE_TYPE.POST:
        bookmarkAction = bookmarkPostRequest;
        loginModalType = TYPES.SAVE_POST;
        break;
    }

    const sessionStorageName = {
      [SAVE_TYPE.ARTICLE]: 'save-article',
      [SAVE_TYPE.ANSWER]: 'save-comment',
      [SAVE_TYPE.OPINION]: 'save-opinion',
      [SAVE_TYPE.POST]: 'save-post',
    };

    let saveData = { id: contentId };
    if (typeParam === SAVE_TYPE.ARTICLE) {
      saveData = { slug };
    }

    if (typeParam === SAVE_TYPE.ANSWER) {
      saveData = {
        commentId: contentId,
        commentableId,
        commentableType,
      };
    }

    if (!isSignedIn) {
      sessionStorage.setItem(
        sessionStorageName[typeParam],
        JSON.stringify(saveData),
      );

      return dispatch(openLoginModal(LOGIN_MODAL[loginModalType]));
    }
    if (bookmarkAction) dispatch(bookmarkAction(saveData));
  };

  if (isText) {
    const isSaved = handleIconFilled(saved);
    return isSaved ? (
      <Text
        intent="success"
        variant="titlesm"
        onClick={() => {
          handleOnClick(type);
          onClickTrack();
        }}
        hoverable
      >
        Saved
      </Text>
    ) : (
      <Text
        intent="secondary"
        variant="titlesm"
        onClick={() => {
          handleOnClick(type);
          onClickTrack();
        }}
        hoverable
      >
        Save
      </Text>
    );
  }

  return (
    <>
      {isAmp ? (
        <a href={`${buildConfig.apiHost}/saved`}>
          <Bookmark size={size} />
        </a>
      ) : (
        <BookmarkButtonBase
          showIconOnly={showIconOnly}
          onClick={() => {
            handleOnClick(type);
            onClickTrack();
          }}
          size={size}
          bookmarked={handleIconFilled(saved)}
        />
      )}
    </>
  );
};

export default BookmarkButton;
