import React from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import dayjs from 'dayjs';
import Image from 'next/image';

import Button from 'app/seedly-component-library/button-new';
import { imageUrl } from 'app/theme';

interface OwnProps {
  title?: string;
  TitleComponent?: React.ReactNode;
  description?: string;
  expiry?: Date;
  ctaText?: string;
  onCTAClick?: () => void;
}

const SignUpBonusCard = (props: OwnProps) => {
  const {
    title = 'SIGN-UP BONUS',
    TitleComponent,
    description = '',
    expiry,
    ctaText,
    onCTAClick,
  } = props;

  return (
    <div className="card relative overflow-hidden border border-neutral-400 shadow-none h-auto">
      <div className="w-1 h-[110%] bg-yellow-100 absolute inset-y-0 left-0" />
      <div className="flex flex-col items-start gap-2 pr-16">
        <p className="text-sm font-bold">
          {TitleComponent || (
            <>
              <span role="img" aria-label="horn">
                📣{' '}
              </span>
              {title}
            </>
          )}
        </p>
        <p>
          <ReactMarkdown plugins={[breaks]}>{description}</ReactMarkdown>
        </p>
        {expiry && (
          <p>Valid till {dayjs(expiry).format('DD MMM YYYY')}. T&Cs apply.</p>
        )}
        {ctaText && onCTAClick && (
          <Button onClick={onCTAClick}>{ctaText}</Button>
        )}
      </div>
      <div className="w-16">
        <div className="absolute top-0 right-0">
          <Image
            src={imageUrl.dotPattern}
            alt="Dot Pattern"
            width={64}
            height={26}
          />
        </div>
        <div className="absolute bottom-0 right-0">
          <Image
            src={imageUrl.handphone}
            alt="Handphone"
            width={64}
            height={89}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpBonusCard;
