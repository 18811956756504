import React from 'react';
import shuffle from 'lodash/shuffle';

import HomeContent, {
  PopularCategory,
  SEOSection,
} from 'app/pageContents/HomeContent';
import BasePage from 'components/misc/BasePage';
import PageHead from 'components/misc/PageHead';

import blogApi from 'entity/blog/blogApi';
import feedApi from 'entity/feed/feedApi';
import groupApi from 'entity/group/groupApi';
import postApi from 'entity/post/postApi';
import productApi from 'api/productApi';

import { configureHomePageHead } from 'utils/configurePageHead';
import {
  selectedCategorySlugs,
  seoSections as SEO_SECTIONS,
} from 'constants/homePage';

import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import type { Group } from 'entity/group/groupRedux';
import type { Post } from 'entity/post/postTypes';
import type { Article } from 'entity/blog/blogTypes';

const Home = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  const {
    trendingGroups,
    trendingArticles,
    popularItems,
    trendingPosts,
    popularCategories,
    seoSections,
  } = props;

  return (
    <BasePage hidePaddingBottom>
      <PageHead {...configureHomePageHead()} />
      <HomeContent
        trendingArticles={trendingArticles}
        trendingGroups={trendingGroups}
        trendingPosts={trendingPosts}
        popularItems={popularItems}
        popularCategories={popularCategories}
        seoSections={seoSections}
      />
    </BasePage>
  );
};

export const getStaticProps: GetStaticProps<{
  trendingArticles: Article[];
  trendingGroups: Group[];
  popularItems: (Post | Article)[];
  trendingPosts: Post[];
  popularCategories: PopularCategory[];
  seoSections: SEOSection[];
}> = async () => {
  const groupsResponse = await groupApi.getGroups();
  const feedsResponse = await feedApi.getTrendingFeeds({});
  const blogResponse = await blogApi.getArticles({
    perPage: 5,
    page: 1,
  });
  const postResponse = await postApi.getTrendingPosts({ per_page: 10 });
  const categoriesResponse = await productApi.getProductCategories();

  const seoSections = await Promise.all(
    SEO_SECTIONS.map(async section => {
      const response = await blogApi.getArticleFromWp(section.articleSlug);

      if (!response.ok) {
        throw new Error('Failed to fetch SEO article');
      }

      return { ...section, article: response.data };
    }),
  );

  if (
    !groupsResponse.ok ||
    !feedsResponse.ok ||
    !blogResponse.ok ||
    !postResponse.ok ||
    !categoriesResponse.ok
  ) {
    throw new Error('Failed to fetch content');
  }

  const trendingArticles = blogResponse.data.posts;
  const trendingCommunity = feedsResponse.data.data;
  const popularItems = shuffle([
    ...trendingArticles.slice(0, 3),
    ...trendingCommunity.slice(0, 3),
  ]);
  const trendingPosts = postResponse.data.data.slice(0, 5);

  const categories = categoriesResponse.data.data.flatMap(c => c.children);

  const popularCategories = await Promise.all(
    categories
      .filter(c => selectedCategorySlugs.includes(c.slug))
      .map(async category => {
        const productsResponse = await productApi.getProducts({
          category_slug: category.slug,
          sort: { by: 'cached_ranking', dir: 'desc' },
          per: 5,
        });

        if (!productsResponse.ok) {
          throw new Error('Failed to fetch products');
        }

        return {
          ...category,
          popularProducts: productsResponse.data.data,
        };
      }),
  );

  return {
    props: {
      trendingGroups: groupsResponse.data.data.slice(0, 5),
      trendingCommunity,
      trendingArticles,
      popularItems,
      trendingPosts,
      popularCategories,
      seoSections,
    },
    revalidate: 60 * 60,
  };
};

export default Home;
