import React, { useEffect, useRef, useState } from 'react';
import { Tabs } from '@component-lib';
import NextImage from 'next/image';
import { useRouter } from 'next/router';

import { homeTabIcons } from 'theme';
import mixpanel, { trackedEvents } from 'utils/mixpanel';

import type { Post } from 'entity/post/postTypes';
import type { Article } from 'entity/blog/blogTypes';
import getSessionStorage from 'utils/getSesssionStorage';

import TabContentCommunity from './TabContentCommunity';
import TabContentBlog from './TabContentBlog';
import TabContentReviews from './TabContentReviews';
import TabContentRewards from './TabContentRewards';

import type { PopularCategory } from '../types';

interface OwnProps {
  trendingArticles: Article[];
  trendingPosts: Post[];
  popularCategories: PopularCategory[];
}

const TabContent = (props: OwnProps) => {
  const { trendingArticles, trendingPosts, popularCategories } = props;
  const tabsRef = useRef(null);
  const router = useRouter();
  const [tabKey, setTabKey] = useState('community');

  useEffect(() => {
    const persistedTabKey = getSessionStorage('home-tab');
    if (persistedTabKey) {
      setTabKey(persistedTabKey);
    }
  }, [router.query.tab]);

  const handleTabsChange = key => {
    mixpanel.track(trackedEvents.HomePage, { param: key });
    const { top } = tabsRef.current.getBoundingClientRect();
    const topWindow = window.scrollY;
    window.scrollTo({ top: top + topWindow - 56, behavior: 'smooth' }); // account for header height

    sessionStorage.setItem('home-tab', key);
    setTabKey(key);
  };

  return (
    <Tabs
      ref={tabsRef}
      activeKey={tabKey}
      items={[
        {
          label: 'Community',
          value: 'community',
          activeIcon: (
            <NextImage
              src={homeTabIcons.communityPrimary}
              alt="community"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          inactiveIcon: (
            <NextImage
              src={homeTabIcons.communitySecondary}
              alt="community"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          children: <TabContentCommunity trendingPosts={trendingPosts} />,
        },
        {
          label: 'Blog',
          value: 'blog',
          activeIcon: (
            <NextImage
              src={homeTabIcons.blogPrimary}
              alt="blog"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          inactiveIcon: (
            <NextImage
              src={homeTabIcons.blogSecondary}
              alt="blog"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          children: <TabContentBlog trendingArticles={trendingArticles} />,
        },
        {
          label: 'Product Reviews',
          value: 'product_reviews',
          activeIcon: (
            <NextImage
              src={homeTabIcons.reviewsPrimary}
              alt="reviews"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          inactiveIcon: (
            <NextImage
              src={homeTabIcons.reviewsSecondary}
              alt="reviews"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          children: <TabContentReviews popularCategories={popularCategories} />,
        },
        {
          label: 'Rewards',
          value: 'rewards',
          activeIcon: (
            <NextImage
              src={homeTabIcons.giftPrimary}
              alt="rewards"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          inactiveIcon: (
            <NextImage
              src={homeTabIcons.giftSecondary}
              alt="rewards"
              width={24}
              height={24}
              layout="fixed"
            />
          ),
          children: <TabContentRewards />,
        },
      ]}
      onChange={handleTabsChange}
    />
  );
};

export default TabContent;
