import React from 'react';

import * as S from './styles';

interface JumbotronProps {
  height?: string;
  desktopBackground?: string;
  mobileBackground?: string;
  backgroundColor?: string;
  showGradient?: boolean;
  children: React.ReactNode;
}

const Jumbotron = (props: JumbotronProps) => {
  const {
    height = '280px', // pre-determined minimum height
    desktopBackground,
    mobileBackground,
    backgroundColor,
    showGradient = false,
  } = props;

  let desktopBgProps = 'none';
  let mobileBgProps = 'none';

  if (desktopBackground) {
    desktopBgProps = showGradient
      ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${desktopBackground}")`
      : `url("${desktopBackground}")`;
  }

  if (mobileBackground) {
    mobileBgProps = showGradient
      ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${mobileBackground}")`
      : `url("${mobileBackground}")`;
  }

  return (
    <S.Wrapper
      {...props}
      desktopBackground={desktopBgProps}
      mobileBackground={mobileBgProps}
      heightProp={height}
      backgroundColor={backgroundColor}
    >
      {props.children}
    </S.Wrapper>
  );
};

export default Jumbotron;
