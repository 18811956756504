import styled from 'styled-components';
import { media } from 'theme';

const bodyWidth = 1024;
const sideBarWidth = 354;
const sideBarMargin = 24;
const mainWidth = bodyWidth - sideBarWidth - sideBarMargin;

export const SideBar = styled.div`
  margin-right: 24px;
  min-width: 354px;
  max-width: 354px;

  ${media.largeAndDown`
    display: none;
  `}
`;

export const Main = styled.div`
  width: ${mainWidth}px;

  ${media.largeAndDown`
    width: 100%;
  `}
`;

export const MobileOnly = styled.div`
  display: none;

  ${media.largeAndDown`
    display: block;
  `}
`;

export const DesktopOnly = styled.div`
  display: block;

  ${media.largeAndDown`
    display: none;
  `}
`;
